import ImageEditor from 'tui-image-editor/dist/tui-image-editor.min';
import { SVG } from '@svgdotjs/svg.js';

window.SVG = SVG;

function checkDateInput() {
    var input = document.createElement('input');
    input.setAttribute('type','date');

    var notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);

    return (input.value !== notADateValue);
}

function createDateInput() {
    $( "input[type=date]" ).each(function() {
        let el = $(this);
        let val = '';
        let valArr = [];
        if (el.prev('input[type=text]').length === 0) {

            if (el.val() != '') {
                valArr = el.val().split('-');
                valArr.reverse();
                val = valArr.join('.');
            }

            let inp = $('<input>', {
                type: 'text',
                class: 'form-control',
                value: val
            });

            el.before(inp);
            inp.datepicker({
                changeMonth: true,
                changeYear: true,
                dateFormat: "dd.mm.yy",
                altFormat: "yy-mm-dd",
                altField: el,
                yearRange: "1900:c",
                dayNamesMin: [ "Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб" ],
                firstDay: 1,
                monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                monthNamesShort: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
            });

            el.css({'display': 'none'});
        }
    });
}
function showLabel(plot) {
    var ctx = plot.getCanvas().getContext("2d"); // get the context
    var xaxis = plot.getXAxes()[0]; // xAxis
    var yaxis = plot.getYAxes()[0]; // yAxis
    var offset = plot.getPlotOffset(); // plots offset
    ctx.font = "14px 'Segoe UI'"; // set a pretty label font
    ctx.fillStyle = "black";
    var pD = plot.getData();  // get your series data
    for (var j = 0; j < pD.length; j++){
        var pdi = pD[j].data;
        for (var i = 0; i < pdi.length; i++){
            var text = pdi[i][1] + '';
            var metrics = ctx.measureText(text);
            var xPos = isNaN(pdi[i][0]) ? (xaxis.p2c(i)+offset.left + pD[j].xoffset) - metrics.width/2 : (xaxis.p2c(pdi[i][0])+offset.left) - metrics.width/2; // place it in the middle of the bar
            var yPos = yaxis.p2c(pdi[i][1]) + offset.top - 2; // place at top of bar, slightly up
            ctx.fillText(text, xPos, yPos);
        }
    }
}

function setAttr(prmName,val){
    let res = '';
    let d = location.href.split("#")[0].split("?");
    let base = d[0];
    let query = d[1];
    if(query) {
        let params = query.split("&");
        for(let i = 0; i < params.length; i++) {
            let keyval = params[i].split("=");
            if(keyval[0] != prmName) {
                res += params[i] + '&';
            }
        }
    }
    res += prmName + '=' + val;
    window.location.href = base + '?' + res + document.location.hash;
    return false;
}

function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value) {

    let options = {
        path: '/'
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

function deleteCookie(name) {
    setCookie(name, "", {
        'max-age': -1
    })
}

window.ImageEditor = ImageEditor;
window.setAttr = setAttr;
window.showLabel = showLabel;
window.setCookie = setCookie;
window.getCookie = getCookie;
window.deleteCookie = deleteCookie;
window.showInfo = showInfo;

function showInfo(type, content, time = 5000) {
    var bc = type === 'error' ? 'error' : 'success';

    $('body').append('<div class="alert alert-' + bc + ' alert-dismissible" role="alert" style="position: fixed; width: calc(100% - 20px); max-width: 500px; right: 20px; bottom: 10px; z-index: 1060;">'+
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+
        content +
        '</div>');

    setTimeout(function () { $('body > .alert').detach(); }, time);
}

$(function() {
    $('.set-fixed').click(function () {
       if ($('body').hasClass('sidebar-collapse')) {
           setCookie('menu-fixed','1');
       } else {
           setCookie('menu-fixed','0');
       }
    });
    /*if (localStorage.getItem('menu-fixed') == 1) {
        $('body').removeClass('sidebar-collapse').addClass('layout-fixed');
    }*/
    $('nav > ul > li > a').click(function (e) {
        e.preventDefault();
        document.location.href = $(this).attr('href');
    });
    let activeMenu = $('nav > ul > li > a.active').parent().data('menu');
    if (activeMenu) {
        setCookie('menu-'+ activeMenu, document.location.href);
    }

    if (!checkDateInput()) {
        createDateInput();
        $(document).on('pjax:success', function() {
            createDateInput();
        })
    } else {
        $('#p0, #w0').on('change', 'input[type=date]', function() {return false;});
        let enter = jQuery.Event( "keydown", { keyCode: 13 } );
        $('#p0, #w0').on('blur', 'input[type=date]', function() {$(this).trigger(enter)});
    }
});